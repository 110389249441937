import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
          <path d="M2641 5264 c-164 -58 -255 -201 -221 -346 27 -112 93 -176 244 -239
156 -64 185 -79 197 -100 17 -34 6 -63 -31 -82 -54 -28 -127 -17 -227 33 l-82
42 -29 -34 c-61 -69 -112 -133 -112 -140 0 -16 111 -80 181 -105 224 -79 463
-12 547 153 43 84 36 214 -15 287 -41 59 -80 84 -225 142 -156 63 -188 82
-188 112 0 39 11 53 52 64 57 15 134 2 197 -33 29 -16 54 -28 55 -26 2 2 28
35 60 75 32 40 56 80 54 89 -4 21 -119 91 -182 109 -65 20 -218 19 -275 -1z"/>
<path d="M6989 5266 c-146 -56 -231 -156 -233 -276 -2 -92 20 -148 83 -211 47
-46 73 -62 161 -98 188 -76 210 -89 210 -128 0 -85 -160 -92 -289 -12 -44 26
-54 29 -67 18 -22 -18 -137 -160 -133 -163 2 -1 22 -16 44 -33 46 -36 137 -75
214 -93 75 -18 221 -8 292 19 149 57 231 203 199 355 -23 111 -79 160 -271
235 -74 29 -144 63 -157 74 -34 32 -26 68 21 91 50 24 128 15 202 -24 l55 -29
65 77 c36 42 65 80 65 84 0 12 -94 74 -150 98 -45 20 -75 24 -165 27 -75 2
-121 -2 -146 -11z"/>
<path d="M3292 4928 c4 -319 6 -348 26 -408 31 -93 72 -153 135 -195 70 -46
140 -65 249 -65 168 0 281 59 343 181 47 92 54 153 55 490 1 173 1 319 0 326
0 9 -33 11 -122 10 l-123 -2 -5 -335 c-5 -321 -6 -337 -27 -376 -25 -48 -67
-69 -131 -68 -59 1 -88 20 -114 73 -22 44 -23 59 -28 376 l-5 330 -128 3 -129
3 4 -343z"/>
<path d="M4320 4775 l0 -496 123 3 122 3 3 130 c2 72 -2 186 -7 255 -6 69 -13
157 -16 195 l-6 70 26 -60 c38 -88 34 -79 164 -347 l120 -248 136 0 135 0 0
495 0 496 -122 -3 -123 -3 0 -220 c0 -124 6 -265 14 -323 7 -57 12 -105 9
-107 -2 -2 -28 51 -56 118 -29 67 -99 214 -155 327 l-102 205 -132 3 -133 3 0
-496z"/>
<path d="M5353 5263 c-10 -4 -13 -112 -13 -494 l0 -489 130 0 130 0 0 166 0
165 51 -3 50 -3 83 -160 83 -160 146 -3 c83 -1 147 1 147 7 0 4 -7 14 -15 21
-8 7 -15 16 -15 20 0 4 -40 79 -90 166 l-89 159 49 42 c112 99 141 272 70 413
-40 79 -133 132 -263 149 -78 11 -431 14 -454 4z m431 -217 c44 -18 66 -52 66
-101 0 -89 -46 -124 -166 -128 l-79 -2 -3 123 -3 122 76 0 c42 0 90 -6 109
-14z"/>
<path d="M6300 4774 l0 -494 130 0 130 0 0 495 0 496 -130 -2 -130 -1 0 -494z"/>
<path d="M7640 4774 l0 -494 325 0 325 0 0 105 0 105 -195 0 -195 0 0 90 0 90
160 0 160 0 0 110 0 110 -160 0 -160 0 0 80 0 80 188 2 187 3 2 100 c1 67 -2
102 -10 107 -7 5 -150 8 -319 7 l-308 0 0 -495z"/>
<path d="M4138 4034 c-5 -4 -8 -169 -8 -366 l0 -358 151 0 c172 0 223 10 297
57 100 63 147 174 140 327 -9 178 -70 270 -213 320 -53 19 -353 36 -367 20z
m298 -175 c65 -31 88 -85 82 -198 -3 -63 -10 -97 -24 -122 -23 -41 -96 -81
-139 -77 l-30 3 -3 208 -2 207 37 0 c20 0 56 -9 79 -21z"/>
<path d="M5031 4022 c-9 -17 -46 -135 -181 -582 -21 -69 -36 -127 -35 -130 2
-3 46 -4 98 -2 l96 3 16 75 17 74 103 0 103 0 17 -75 16 -75 100 0 c65 0 99 4
99 11 0 6 -22 84 -50 173 -27 88 -76 247 -108 351 l-58 190 -111 3 c-101 2
-113 1 -122 -16z m179 -404 c0 -5 -29 -8 -65 -8 -36 0 -65 3 -65 7 0 3 12 56
26 117 14 61 28 122 31 136 4 18 15 -14 39 -110 18 -74 33 -138 34 -142z"/>
<path d="M5472 3958 l3 -83 90 -1 90 -2 3 -281 2 -281 95 0 95 0 0 280 0 279
98 3 97 3 0 80 0 80 -288 3 -288 2 3 -82z"/>
<path d="M6297 4033 c-5 -8 -45 -136 -112 -358 -18 -60 -50 -165 -71 -233 -20
-67 -35 -124 -33 -127 7 -7 179 -8 186 -1 4 3 14 37 23 74 l16 67 102 3 101 3
11 -38 c5 -21 13 -55 16 -75 l6 -37 100 -3 c55 -2 102 0 103 3 4 5 -30 121
-89 309 -13 41 -46 147 -72 235 -26 88 -52 166 -57 173 -10 13 -222 18 -230 5z
m137 -243 c8 -41 20 -91 26 -110 19 -66 16 -70 -48 -70 -32 0 -61 2 -64 5 -2
3 9 66 26 140 17 74 33 129 37 123 4 -7 14 -47 23 -88z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
